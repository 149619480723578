import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { request } from '../../../request';
import backoffice from '../../../request/apis/backoffice';
import DLTransferListStyle from 'assets/jss/views/DLTransferListStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip, withStyles } from '@material-ui/core';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import CardFooter from 'components/core/Card/CardFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Button from 'components/core/CustomButtons/Button';
import CardIcon from 'components/core/Card/CardIcon';
import DateInput from 'components/core/dateInput';
// icons
import SearchIcon from '@material-ui/icons/Search';
import { DL_REQUEST_STATUS, PERMISSIONS, ROWS_PER_PAGE } from '../../../constants';
import { hasPermission } from '../../../utils';
import PaginationActions from '../../../components/core/Pagination/PaginationActions';

const DLTransferList = props => {
  const {
    classes,
    page,
    setPage,
    dlRequestId,
    setDLRequestId,
    dlRequestStatus,
    setDLRequestStatus,
    idNumber,
    setIdNumber,
    iqamaNumber,
    setIqamaNumber,
    currentSponsor,
    setCurrentSponsor,
    createdFrom,
    setCreatedFrom,
    createdTo,
    setCreatedTo,
    processedFrom,
    setProcessedFrom,
    processedTo,
    setProcessedTo,
  } = props;

  const [isFirstFetch, setIsFirstFetch] = useState(true);

  const [filters, setFilters] = useState({
    // page: page,
    id: dlRequestId,
    id_number: idNumber,
    iqama_number: iqamaNumber,
    status: dlRequestStatus,
    current_sponsor: currentSponsor,
    created_from: createdFrom,
    created_to: createdTo,
    processed_from: processedFrom,
    processed_to: processedTo,
  });

  const updateFilter = () => {
    setFilters({
      // page,
      id: dlRequestId,
      id_number: idNumber,
      iqama_number: iqamaNumber,
      status: dlRequestStatus,
      current_sponsor: currentSponsor,
      created_from: createdFrom,
      created_to: createdTo,
      processed_from: processedFrom,
      processed_to: processedTo,
    });
  };

  const fetchRequests = async () => {
    const response = await request.GET(backoffice.INQUIRY_DL_TRANSFER, { ...filters, page }, false);
    return response.data;
  };

  const {
    isLoading,
    isSuccess,
    isError,
    data: pagination,
    isRefetching,
    refetch,
    isIdle,
  } = useQuery(['inquiry.dl_transfer', filters, page], fetchRequests, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const onSearch = event => {
    event.preventDefault();
    updateFilter();
    setPage(1);

    if (isFirstFetch) {
      refetch();
      setIsFirstFetch(false);
    }
  };

  const changePage = p => {
    setPage(p + 1);
    refetch();
  };

  useEffect(
    () => {
      if (!isFirstFetch) {
        refetch();
      }
    },
    [filters, page, isFirstFetch],
  );

  const showDlRequest = id => event => {
    event.preventDefault();
    props.history.push(`/inquiries/dl/${id}`);
  };

  const processDLRequest = id => event => {
    event.preventDefault();
    props.history.push(`/inquiries/${id}/process`);
  };

  return (
    <Grid container>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon title="البحث" subtitle="الإستعلام عن الطلبات">
            <CardIcon color="info" isTableIcon>
              <SearchIcon />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Grid container>
              <GridItem xs={12} sm={6} md={3}>
                <input
                  className={classes.filterInput}
                  placeholder="رقم الطلب"
                  onChange={event => setDLRequestId(event.target.value)}
                  value={dlRequestId}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Select
                  className={[
                    classes.filterInput,
                    classes.select,
                    !dlRequestStatus ? classes.placeholder : {},
                  ]}
                  onChange={event => setDLRequestStatus(event.target.value)}
                  value={dlRequestStatus}
                  displayEmpty
                >
                  <MenuItem disabled selected value="" className={classes.selectItem}>
                    حالة الطلب
                  </MenuItem>
                  <MenuItem value="" className={classes.selectItem}>
                    جميع الحالات
                  </MenuItem>
                  <MenuItem value="WAITING_EMPLOYER_RESPONSE" className={classes.selectItem}>
                    بإنتظار رد صاحب العمل
                  </MenuItem>
                  <MenuItem value="WAITING_FINANCIAL_DOCUMENTS" className={classes.selectItem}>
                    بإنتظار رفع إثبات القدرة المالية
                  </MenuItem>
                  <MenuItem value="PENDING_BACKOFFICE" className={classes.selectItem}>
                    تحت المعالجة
                  </MenuItem>
                  <MenuItem value="REJECTED" className={classes.selectItem}>
                    مرفوضة
                  </MenuItem>
                  <MenuItem value="VALID" className={classes.selectItem}>
                    سارٍ
                  </MenuItem>
                  <MenuItem value="CANCELLED" className={classes.selectItem}>
                    ملغي من قِبل صاحب العمل
                  </MenuItem>
                  <MenuItem value="WAITING_ELIGIBILITY_CORRECTION" className={classes.selectItem}>
                    بإنتظار التصحيح من العميل
                  </MenuItem>
                  <MenuItem value="CANCELLED_FOR_INELIGIBILITY" className={classes.selectItem}>
                    ملغي لعدم الأهلية
                  </MenuItem>
                  <MenuItem value="PAYMENT_EXPIRED" className={classes.selectItem}>
                    انتهى الدفع
                  </MenuItem>
                  <MenuItem value="RESPONSE_EXPIRED" className={classes.selectItem}>
                    منتهي
                  </MenuItem>
                  <MenuItem value="COMPLETE" className={classes.selectItem}>
                    مُكتمل
                  </MenuItem>
                  <MenuItem
                    value="CANCELLED_FOR_INCOMPLETE_PROCEDURES"
                    className={classes.selectItem}
                  >
                    ملغي لعدم اكمال الاجراءات
                  </MenuItem>
                  <MenuItem value="UNDER_FINAL_REVIEW" className={classes.selectItem}>
                    تحت المراجعة النهائية
                  </MenuItem>
                  <MenuItem value="QUALITY" className={classes.selectItem}>
                    الجودة
                  </MenuItem>
                  <MenuItem value="ESCALATED" className={classes.selectItem}>
                    مصعد
                  </MenuItem>
                </Select>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <input
                  className={classes.filterInput}
                  placeholder="رقم هوية العميل"
                  onChange={event => setIdNumber(event.target.value)}
                  value={idNumber}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <input
                  className={classes.filterInput}
                  placeholder="هوية العامل\ـة"
                  onChange={event => setIqamaNumber(event.target.value)}
                  value={iqamaNumber}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <DateInput
                  className={classes.filterInput}
                  placeholder="تقديم الطلب من تاريخ"
                  onChange={event => setCreatedFrom(event.target.value)}
                  value={createdFrom}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <DateInput
                  className={classes.filterInput}
                  placeholder="الى تاريخ"
                  onChange={event => setCreatedTo(event.target.value)}
                  value={createdTo}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <DateInput
                  className={classes.filterInput}
                  placeholder="معالجة الطلب من تاريخ"
                  onChange={event => setProcessedFrom(event.target.value)}
                  value={processedFrom}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <DateInput
                  className={classes.filterInput}
                  placeholder="الى تاريخ"
                  onChange={event => setProcessedTo(event.target.value)}
                  value={processedTo}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <input
                  className={classes.filterInput}
                  placeholder="اسم المنشأة"
                  onChange={event => setCurrentSponsor(event.target.value)}
                  value={currentSponsor}
                />
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Button className={classes.searchBtn} color="primary" onClick={onSearch}>
                  بحث
                </Button>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
        {isIdle && (
          <Card>
            <div style={{ textAlign: 'center', padding: '10px' }}>اختر معطياتك واضغط زر البحث</div>
          </Card>
        )}
        {(isLoading || isRefetching) && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress className={classes.progress} />
          </div>
        )}
        {isError && (
          <Card>
            <div style={{ textAlign: 'center', padding: '10px' }}>
              حدث خطأ عند الاستعلام عن الطلبات, نرجو المحاولة مرة أخرى لاحقا
            </div>
          </Card>
        )}
        {(typeof pagination !== 'undefined' && pagination.total) === 0 && (
          <Card>
            <div style={{ textAlign: 'center', padding: '10px' }}>لا يوجد نتائج</div>
          </Card>
        )}
        {isSuccess && (
          <List
            classes={classes}
            auth={props.auth}
            pagination={pagination}
            page={page}
            onChangePage={changePage}
            onShow={showDlRequest}
            onProcess={processDLRequest}
          />
        )}
      </GridItem>
    </Grid>
  );
};

const List = ({ classes, auth, pagination, page, onChangePage, onShow, onProcess }) => {
  const isProcessable = processingRequest => {
    return processingRequest.existence.exist && processingRequest.existence.processable;
  };

  const tooltipMessage = processingRequest => {
    return !processingRequest.existence.exist
      ? 'يمكنك فقط معالجة الطلبات الموجودة في مكتب معالجة طلبات الأفراد الجديد'
      : 'يمكنك فقط معالجة الطلبات التي لم تتم معالجتها او تصعيدها';
  };

  return (
    <Card>
      <CardBody>
        <p>النتائج : {pagination.total || 0} طلب</p>
        <div className={classes.labelsRow}>
          <span className={classes.label}>رقم الطلب </span>
          <span className={classes.label}>حالة الطلب </span>
          <span className={classes.label}>تاريخ الطلب </span>
          <span className={`${classes.label} ${classes.l}`}>التفاصيل</span>
          <span className={classes.label} />
        </div>

        {pagination.data.length
          ? pagination.data.map(record => {
              const date = new Date(record.created_at);
              const day = `${date.getDate()}`.padStart(2, '0');
              const month = `${date.getMonth() + 1}`.padStart(2, '0');
              const year = date.getFullYear();
              return (
                <div className={classes.dataRow}>
                  <span className={classes.data}>{record.id}</span>
                  <span className={classes.data}>
                    <span className={`${classes.stateTag} ${classes[`status_${record.status}`]}`}>
                      {DL_REQUEST_STATUS[record.status]}
                    </span>
                  </span>
                  <span className={classes.data}>{`${year}-${month}-${day}`}</span>
                  <span className={`${classes.data} ${classes.l}`}>
                    {`${record.occupation.label} / ${record.nationality.label} `}
                  </span>
                  <Button
                    className={classes.showBtn}
                    href={`/inquiries/dl/${record.id}`}
                    onClick={onShow(record.id)}
                  >
                    عرض
                  </Button>
                  {hasPermission(PERMISSIONS.INQUIRY_PROCESSING.KEY, auth) && (
                    <Tooltip
                      title={tooltipMessage(record)}
                      disableFocusListener={isProcessable(record)}
                      disableHoverListener={isProcessable(record)}
                      disableTouchListener={isProcessable(record)}
                    >
                      <span>
                        <Button
                          className={
                            isProcessable(record) ? classes.processBtn : classes.processBtnDisabled
                          }
                          disabled={!isProcessable(record)}
                          href={`/inquiries/${record.existence.backoffice_id}/process`}
                          onClick={onProcess(record.existence.backoffice_id)}
                        >
                          معالجة
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              );
            })
          : null}
      </CardBody>
      <CardFooter>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <TablePagination
            style={{ border: 'none' }}
            count={pagination.total || 1}
            rowsPerPage={ROWS_PER_PAGE}
            page={page - 1}
            onChangePage={(event, thePage) => {
              onChangePage(thePage);
            }}
            ActionsComponent={PaginationActions}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => ` ${from}-${to} من أصل ${count}`}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    auth: {
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
    },
  };
};

export default compose(
  withStyles(DLTransferListStyle),
  connect(mapStateToProps),
  withRouter,
)(DLTransferList);
