import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import CardFooter from 'components/core/Card/CardFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/core/CustomButtons/Button';
import CardIcon from 'components/core/Card/CardIcon';
import DateInput from 'components/core/dateInput';
// icons
import SearchIcon from '@material-ui/icons/Search';
// style
import InquiriesStyle from 'assets/jss/views/InquiriesStyle';
// constatns
import {
  REQUEST_STATUS,
  ROWS_PER_PAGE,
  PERMISSIONS,
  FARMER_VISA_REQUEST_SOURCE,
} from '../../constants';
import { request, URLs } from '../../request';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageTaps from '../../components/core/CustomTabs/PageTaps';
import { Tooltip } from '@material-ui/core';
import { hasPermission } from '../../utils';
import DLTransferList from './List/DLTransferList';
import Ind2IndTransferList from './List/Ind2IndTransferList';

class Inquiries extends React.Component {
  state = {
    data: [],
    gios: [],
    agents: [],
    currentPage: 1,
    officeName: '',
    clientType: '',
    requestStatus: '',
    isDisabled: '',
    processingFromDate: '',
    processingToDate: '',
    requestFromDate: '',
    requestToDate: '',
    visaType: '',
    agentId: '',
    dl: {
      data: [],
      processingFromDate: '',
      processingToDate: '',
      requestFromDate: '',
      requestToDate: '',
      currentPage: 1,
    },
    dlRequestId: '',
    dlStatus: '',
    dlIdNumber: '',
    dlLaborerIqama: '',
    dlCurrentSponsor: '',
    dlCreatedFrom: '',
    dlCreatedTo: '',
    dlUpdatedFrom: '',
    dlUpdatedTo: '',
    farmerVisa: {
      data: [],
      processingFromDate: '',
      processingToDate: '',
      requestFromDate: '',
      requestToDate: '',
      currentPage: 1,
    },
    farmerRequestId: '',
    farmerRequestUserId: '',
    farmerStatus: '',
    farmerCreatedFrom: '',
    farmerCreatedTo: '',
    farmerCreatedAt: '',
    farmerVisaSource: '',

    // New DL Transfer Filters
    newDlPage: 1,
    newDlRequestId: null,
    newDlRequestStatus: '',
    newDlIdNumber: null,
    newDlIqamaNumber: null,
    newDlCurrentSponsor: null,
    newDlCreatedFrom: null,
    newDlCreatedTo: null,
    newDlProcessedFrom: null,
    newDlProcessedTo: null,
    api_loading: null,

    // Ind2Ind Transfer Filters
    Ind2IndPage: 1,
    Ind2IndRequestId: null,
    Ind2IndRequestStatus: '',
    Ind2IndNewSponsor: null,
    Ind2IndLaborer: null,
    Ind2IndCurrentSponsor: null,
    Ind2IndCreatedFrom: null,
    Ind2IndCreatedTo: null,
    Ind2IndProcessedFrom: null,
    Ind2IndProcessedTo: null,
  };

  requestStatuses = [
    '',
    REQUEST_STATUS.IN_PROCESS,
    REQUEST_STATUS.APPROVED,
    REQUEST_STATUS.REJECTED,
    REQUEST_STATUS.CANCELED,
    REQUEST_STATUS.PENDING,
    '',
    '',
    '',
    REQUEST_STATUS.WAITING_ECONTRACT,
    '',
    REQUEST_STATUS.EXPIRED,
  ];

  backofficeStatuses = {
    null: 'تحت المعالجة',
    1: 'تحت المعالجة',
    7: 'مصعد',
    8: 'جودة',
  };

  requestDLStatuses = [
    '',
    REQUEST_STATUS.PENDING,
    REQUEST_STATUS.WAITING_FOR_COMPANY,
    REQUEST_STATUS.WAITING_FOR_MINISTRY,
    REQUEST_STATUS.APPROVED,
    REQUEST_STATUS.REJECTED,
    REQUEST_STATUS.EXPIRED,
  ];

  farmerRequestStatuses = [
    '',
    REQUEST_STATUS.IN_PROCESS,
    REQUEST_STATUS.APPROVED,
    REQUEST_STATUS.REJECTED,
  ];

  farmerVisaRequestSource = [
    '',
    FARMER_VISA_REQUEST_SOURCE.ONLINE,
    FARMER_VISA_REQUEST_SOURCE.GIO,
    '',
    '',
    FARMER_VISA_REQUEST_SOURCE.OUTSIDE,
  ];

  componentDidMount() {
    this.fetchGios();
    this.fetchAgents();
  }

  fetchData = () => {
    this.setState({ api_loading: true });
    request
      .GET(URLs.backoffice.INQUIRY_ISSUE_REQUSTS, {
        page: this.state.currentPage,
        ...this.getParams(),
      })
      .then(res => this.setState({ data: res.data.data, total: res.data.total }))
      .catch(res => {
        if (res.response.data && res.response.data.error && res.response.data.error.code === 0) {
          this.setState({ data: [], total: 0 });
        }
      })
      .finally(() => {
        this.setState({ api_loading: false });
      });
  };

  fetchDLData = () => {
    this.setState({ api_loading: true });
    request
      .GET(URLs.backoffice.INQUIRY_LEGACY_DL_TRANCFER, {
        page: this.state.dl.currentPage,
        ...this.getDLParams(),
      })
      .then(res =>
        this.setState(prevState => ({
          dl: Object.assign({}, prevState.dl, { data: res.data.data, total: res.data.total }),
        })),
      )
      .catch(res => {
        if (res.response.data && res.response.data.error && res.response.data.error.code === 0) {
          this.setState(prevState => ({
            dl: Object.assign({}, prevState.dl, { data: [], total: 0 }),
          }));
        }
      })
      .finally(() => {
        this.setState({ api_loading: false });
      });
  };

  fetchFarmerData = () => {
    this.setState({ api_loading: true });
    request
      .GET(URLs.evisa.FARMER_VISA_REQUESTS, {
        page: this.state.farmerVisa.currentPage,
        ...this.getFarmerVisaParams(),
      })
      .then(res =>
        this.setState(prevState => ({
          farmerVisa: Object.assign({}, prevState.farmerVisa, {
            data: res.data.data,
            total: res.data.total,
          }),
        })),
      )
      .catch(res => {
        if (res.response.data && res.response.data.error && res.response.data.error.code === 0) {
          this.setState(prevState => ({
            farmerVisa: Object.assign({}, prevState.farmerVisa, { data: [], total: 0 }),
          }));
        }
      })
      .finally(() => {
        this.setState({ api_loading: false });
      });
  };

  fetchGios = () => request.GET(URLs.evisa.GIOS).then(res => this.setState({ gios: res.data }));

  fetchAgents = () =>
    request
      .GET(URLs.backoffice.AGENTS, {
        exclude_audit: true,
      })
      .then(res => this.setState({ agents: res.data.users }));

  /* filters */

  handleRequestId = e => this.setState({ requestId: e.target.value });

  handleRequestStatus = e => this.setState({ requestStatus: e.target.value });

  handleExactRequestDate = e => this.setState({ exactRequestDate: e.target.value });

  handleClientType = e => this.setState({ clientType: e.target.value });

  handleClientId = e => this.setState({ clientId: e.target.value });

  handleOffice = e => this.setState({ officeName: e.target.value });

  handleRequestFromDate = e => this.setState({ requestFromDate: e.target.value });

  handleRequestToDate = e => this.setState({ requestToDate: e.target.value });

  handleProcessingFromDate = e => this.setState({ processingFromDate: e.target.value });

  handleProcessingToDate = e => this.setState({ processingToDate: e.target.value });

  handleVisaType = e => this.setState({ visaType: e.target.value });

  handleAgent = e => this.setState({ agentId: e.target.value });

  handleIsDisabled = e => this.setState({ isDisabled: e.target.value });

  handleOnChangePage = (_, p) => {
    this.setState({ currentPage: p + 1 });
    this.fetchData();
  };

  handleOnChangeDLPage = (_, p) => {
    this.setState(
      prevState => ({
        dl: Object.assign({}, prevState.dl, { currentPage: p + 1 }),
      }),
      this.fetchDLData,
    );
  };

  handleSearch = () => this.fetchData();

  handleDlSearch = () => this.fetchDLData();

  handleDlRequestId = e => this.setState({ dlRequestId: e.target.value });

  handleDlRequestStatus = e => this.setState({ dlStatus: e.target.value });

  handleDlIdNumber = e => this.setState({ dlIdNumber: e.target.value });

  handleDlLaborerIqama = e => this.setState({ dlLaborerIqama: e.target.value });

  handleDlCurrentSponsor = e => this.setState({ dlCurrentSponsor: e.target.value });

  handleDlCreatedFromDate = e => this.setState({ dlCreatedFrom: e.target.value });

  handleDlCreatedToDate = e => this.setState({ dlCreatedTo: e.target.value });

  handleDlUpdatedFromDate = e => this.setState({ dlUpdatedFrom: e.target.value });

  handleDlUpdatedToDate = e => this.setState({ dlUpdatedTo: e.target.value });

  handleOnChangeFarmerPage = (_, p) => {
    this.setState(
      prevState => ({
        farmerVisa: Object.assign({}, prevState.farmerVisa, { currentPage: p + 1 }),
      }),
      this.fetchFarmerData,
    );
  };

  handleSearch = () => this.fetchData();

  handleFarmerSearch = () => this.fetchFarmerData();

  handleFarmerRequestId = e => this.setState({ farmerRequestId: e.target.value });

  handleFarmerRequestUserId = e => this.setState({ farmerRequestUserId: e.target.value });

  handleFarmerRequestStatus = e => this.setState({ farmerStatus: e.target.value });

  handleFarmerCreatedAt = e => this.setState({ farmerCreatedAt: e.target.value });

  handleFarmerCreatedFromDate = e => this.setState({ farmerCreatedFrom: e.target.value });

  handleFarmerCreatedToDate = e => this.setState({ farmerCreatedTo: e.target.value });

  handleFarmerVisaSource = e => this.setState({ farmerVisaSource: e.target.value });

  /* functions */

  isProcessable = processingRequest => {
    return (
      processingRequest.existence &&
      processingRequest.existence.exist &&
      processingRequest.existence.processable
    );
  };

  tooltipMessage = processingRequest => {
    return !processingRequest.exist
      ? 'يمكنك فقط معالجة الطلبات الموجودة في مكتب معالجة طلبات الأفراد الجديد'
      : 'يمكنك فقط معالجة الطلبات التي لم تتم معالجتها او تصعيدها';
  };

  showIssue = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/show/${id}`);
  };

  showDlRequest = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/dl/${id}`);
  };

  showFarmerRequest = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/farmerVisa/${id}`);
  };

  processIssueRequest = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/${id}/process`);
  };

  processDLRequest = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/${id}/process`);
  };

  processFarmerRequest = id => event => {
    event.preventDefault();
    this.props.history.push(`/inquiries/${id}/process`);
  };

  getParams = () => ({
    request_id: this.state.requestId,
    status: this.state.requestStatus === 'all' ? '' : this.state.requestStatus,
    date: this.state.exactRequestDate,
    request_channel: this.state.clientType === 'all' ? '' : this.state.clientType,
    id_number: this.state.clientId,
    gio: this.state.officeName === 'all' ? '' : this.state.officeName,
    request_from: this.state.requestFromDate,
    request_to: this.state.requestToDate,
    processing_from: this.state.processingFromDate,
    processing_to: this.state.processingToDate,
    visa_type: this.state.visaType,
    agentId: this.state.agentId === 'all' ? '' : this.state.agentId,
    is_disable: this.state.isDisabled === 'all' ? '' : this.state.isDisabled,
  });

  getDLParams = () => ({
    request_id: this.state.dlRequestId,
    status: this.state.dlStatus,
    id_number: this.state.dlIdNumber,
    laborer_iqama: this.state.dlLaborerIqama,
    current_sponsor: this.state.dlCurrentSponsor,
    created_from: this.state.dlCreatedFrom,
    created_to: this.state.dlCreatedTo,
    updated_from: this.state.dlUpdatedFrom,
    updated_to: this.state.dlUpdatedTo,
  });

  getFarmerVisaParams = () => ({
    request_id: this.state.farmerRequestId,
    status: this.state.farmerStatus,
    created_at: this.state.farmerCreatedAt,
    created_from: this.state.farmerCreatedFrom,
    created_to: this.state.farmerCreatedTo,
    source: this.state.farmerVisaSource,
    id_number: this.state.farmerRequestUserId,
  });

  renderIssueType = issue => {
    return issue.visa_replacement_id === null || issue.visa_replacement_id === 0
      ? 'جديدة'
      : 'بديلة';
  };

  render() {
    const { classes } = this.props;
    const {
      requestId,
      requestStatus,
      exactRequestDate,
      clientType,
      clientId,
      officeName,
      requestFromDate,
      requestToDate,
      processingFromDate,
      processingToDate,
      isDisabled,
      visaType,
      data,
      total,
      gios,
      agents,
      agentId,
      dl,
      dlRequestId,
      dlStatus,
      dlIdNumber,
      dlLaborerIqama,
      dlCurrentSponsor,
      dlCreatedFrom,
      dlCreatedTo,
      dlUpdatedFrom,
      dlUpdatedTo,
      farmerVisa,
      farmerRequestId,
      farmerRequestUserId,
      farmerStatus,
      farmerVisaSource,
      farmerCreatedFrom,
      farmerCreatedTo,
    } = this.state;

    const pages = [];
    pages.push({
      title: 'التأشيرات',
      link: '/inquiries/list',
      content: (
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon title="البحث" subtitle="الإستعلام عن الطلبات">
                <CardIcon color="info" isTableIcon>
                  <SearchIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Grid container>
                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="رقم الطلب"
                      onChange={this.handleRequestId}
                      value={requestId}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !requestStatus ? classes.placeholder : {},
                      ]}
                      onChange={this.handleRequestStatus}
                      value={requestStatus}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        حالة الطلب
                      </MenuItem>
                      <MenuItem value="all" className={classes.selectItem}>
                        الكل
                      </MenuItem>
                      <MenuItem value="1" className={classes.selectItem}>
                        تحت المعالجة
                      </MenuItem>
                      <MenuItem value="2" className={classes.selectItem}>
                        مقبول
                      </MenuItem>
                      <MenuItem value="3" className={classes.selectItem}>
                        مرفوض
                      </MenuItem>
                      <MenuItem value="4" className={classes.selectItem}>
                        ملغى
                      </MenuItem>
                      <MenuItem value="5" className={classes.selectItem}>
                        بانتظار الدفع
                      </MenuItem>
                      <MenuItem value="7" className={classes.selectItem}>
                        مصعد
                      </MenuItem>
                      <MenuItem value="8" className={classes.selectItem}>
                        جودة
                      </MenuItem>
                      <MenuItem value="11" className={classes.selectItem}>
                        منتهي لعدم الدفع
                      </MenuItem>
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="تاريخ تقديم الطلب"
                      onChange={this.handleExactRequestDate}
                      value={exactRequestDate}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !clientType ? classes.placeholder : {},
                      ]}
                      onChange={this.handleClientType}
                      value={clientType}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        نوع مقدم الطلب
                      </MenuItem>
                      <MenuItem value="all" className={classes.selectItem}>
                        الكل
                      </MenuItem>
                      <MenuItem value="online" className={classes.selectItem}>
                        مستخدم
                      </MenuItem>
                      <MenuItem value="pro" className={classes.selectItem}>
                        مكتب استقدام
                      </MenuItem>
                      <MenuItem value="gio" className={classes.selectItem}>
                        مكتب حكومي
                      </MenuItem>
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="رقم هوية العميل"
                      onChange={this.handleClientId}
                      value={clientId}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !officeName ? classes.placeholder : {},
                      ]}
                      onChange={this.handleOffice}
                      value={officeName}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        مكتب GIO
                      </MenuItem>
                      <MenuItem value="all" className={classes.selectItem}>
                        الكل
                      </MenuItem>
                      {gios.length
                        ? gios.map(gio => (
                            <MenuItem value={gio.id} className={classes.selectItem}>
                              {gio.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="تقديم الطلب من تاريخ"
                      onChange={this.handleRequestFromDate}
                      value={requestFromDate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="الى تاريخ"
                      onChange={this.handleRequestToDate}
                      value={requestToDate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="معالجة الطلب من تاريخ"
                      onChange={this.handleProcessingFromDate}
                      value={processingFromDate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="الى تاريخ"
                      onChange={this.handleProcessingToDate}
                      value={processingToDate}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !isDisabled ? classes.placeholder : {},
                      ]}
                      displayEmpty
                      onChange={this.handleIsDisabled}
                      value={isDisabled}
                    >
                      <MenuItem value="" disabled className={classes.selectItem}>
                        ذوي الإعاقة
                      </MenuItem>
                      <MenuItem value="all" className={classes.selectItem}>
                        الكل
                      </MenuItem>
                      <MenuItem value="1" className={classes.selectItem}>
                        نعم
                      </MenuItem>
                      <MenuItem value="0" className={classes.selectItem}>
                        لا
                      </MenuItem>
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !isDisabled ? classes.placeholder : {},
                      ]}
                      displayEmpty
                      onChange={this.handleVisaType}
                      value={visaType}
                    >
                      <MenuItem value="" disabled className={classes.selectItem}>
                        نوع التأشيرة
                      </MenuItem>
                      <MenuItem value="new" className={classes.selectItem}>
                        طلب جديد
                      </MenuItem>
                      <MenuItem value="replacement" className={classes.selectItem}>
                        بديلة
                      </MenuItem>
                      <MenuItem value="vip" className={classes.selectItem}>
                        طلب استثنائي
                      </MenuItem>
                      <MenuItem value="unified" className={classes.selectItem}>
                        طلب الرحلة الموحدة
                      </MenuItem>
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !agentId ? classes.placeholder : {},
                      ]}
                      onChange={this.handleAgent}
                      value={agentId}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        الموظف
                      </MenuItem>
                      <MenuItem value="all" className={classes.selectItem}>
                        الكل
                      </MenuItem>
                      {agents.length
                        ? agents.map(agent => (
                            <MenuItem value={agent.id} className={classes.selectItem}>
                              {agent.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      className={classes.searchBtn}
                      color="primary"
                      onClick={this.handleSearch}
                    >
                      بحث
                    </Button>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>

            {typeof total === 'undefined' && (
              <Card>
                <div style={{ textAlign: 'center', padding: '10px' }}>
                  اختر معطياتك واضغط زر البحث
                </div>
              </Card>
            )}
            {this.state.api_loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} />
              </div>
            )}
            {!this.state.api_loading &&
              total === 0 && (
                <Card>
                  <div style={{ textAlign: 'center', padding: '10px' }}>لا يوجد نتائج</div>
                </Card>
              )}

            {!this.state.api_loading &&
              total > 0 && (
                <Card>
                  <CardBody>
                    <p>النتائج : {total || 0} طلب</p>
                    <div className={classes.labelsRow}>
                      <span className={classes.label}>رقم الطلب </span>
                      <span className={classes.label}>حالة الطلب </span>
                      <span className={classes.label}>تاريخ الطلب </span>
                      <span className={`${classes.label} ${classes.l}`}>التفاصيل</span>
                      <span className={classes.label}>نوع التأشيرة</span>
                      <span className={classes.label} />
                    </div>

                    {data.length
                      ? data.map(issue => {
                          const date = new Date(issue.created_at);
                          const day = `${date.getDate()}`.padStart(2, '0');
                          const month = `${date.getMonth() + 1}`.padStart(2, '0');
                          const year = date.getFullYear();
                          return (
                            <div className={classes.dataRow}>
                              <span className={classes.data}>{issue.id}</span>
                              <span className={classes.data}>
                                <span
                                  className={`${classes.stateTag} ${
                                    issue.status === 1
                                      ? classes[`status${issue.backoffice_status}`]
                                      : classes[`status${issue.status}`]
                                  }`}
                                >
                                  {issue.status === 1
                                    ? this.backofficeStatuses[issue.backoffice_status]
                                    : this.requestStatuses[issue.status]}
                                </span>
                              </span>
                              <span className={classes.data}>{`${year}-${month}-${day}`}</span>
                              <span className={`${classes.data} ${classes.l}`}>
                                {`${issue.occupation.label} / ${issue.nationality.label} / ${
                                  issue.visaIssuePlace.label
                                } `}
                              </span>
                              <span className={classes.data}>{this.renderIssueType(issue)}</span>
                              <Button
                                className={classes.showBtn}
                                href={`/inquiries/show/${issue.id}`}
                                onClick={this.showIssue(issue.id)}
                              >
                                عرض
                              </Button>
                              {hasPermission(
                                PERMISSIONS.INQUIRY_PROCESSING.KEY,
                                this.props.auth,
                              ) && (
                                <Tooltip
                                  title={this.tooltipMessage(issue)}
                                  disableFocusListener={this.isProcessable(issue)}
                                  disableHoverListener={this.isProcessable(issue)}
                                  disableTouchListener={this.isProcessable(issue)}
                                >
                                  <span>
                                    <Button
                                      className={
                                        this.isProcessable(issue)
                                          ? classes.processBtn
                                          : classes.processBtnDisabled
                                      }
                                      disabled={!this.isProcessable(issue)}
                                      href={`/inquiries/${issue.existence.backoffice_id}/process`}
                                      onClick={this.processIssueRequest(
                                        issue.existence.backoffice_id,
                                      )}
                                    >
                                      معالجة
                                    </Button>
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </CardBody>
                  <CardFooter>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                      <TablePagination
                        style={{ border: 'none' }}
                        count={this.state.total || 1}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={this.state.currentPage - 1 || 0}
                        onChangePage={this.handleOnChangePage}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) =>
                          ` ${from}-${to} من أصل ${count}`
                        }
                      />
                    </div>
                  </CardFooter>
                </Card>
              )}
          </GridItem>
        </Grid>
      ),
    });

    pages.push({
      title: 'نقل الخدمات',
      link: '/inquiries/list/1',
      content: (
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon title="البحث" subtitle="الإستعلام عن الطلبات">
                <CardIcon color="info" isTableIcon>
                  <SearchIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Grid container>
                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="رقم الطلب"
                      onChange={this.handleDlRequestId}
                      value={dlRequestId}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !dlStatus ? classes.placeholder : {},
                      ]}
                      onChange={this.handleDlRequestStatus}
                      value={dlStatus}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        حالة الطلب
                      </MenuItem>
                      <MenuItem value="" className={classes.selectItem}>
                        جميع الحالات
                      </MenuItem>
                      <MenuItem value="1" className={classes.selectItem}>
                        تحت المعالجة
                      </MenuItem>
                      <MenuItem value="2" className={classes.selectItem}>
                        بانتظار موافقة المنشأة
                      </MenuItem>
                      <MenuItem value="3" className={classes.selectItem}>
                        تحت المراجعة النهائية
                      </MenuItem>
                      <MenuItem value="4" className={classes.selectItem}>
                        مقبول
                      </MenuItem>
                      <MenuItem value="5" className={classes.selectItem}>
                        مرفوض
                      </MenuItem>
                      <MenuItem value="6" className={classes.selectItem}>
                        منتهي
                      </MenuItem>
                      <MenuItem value="7" className={classes.selectItem}>
                        مصعد
                      </MenuItem>
                      <MenuItem value="8" className={classes.selectItem}>
                        جودة
                      </MenuItem>
                    </Select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="رقم هوية العميل"
                      onChange={this.handleDlIdNumber}
                      value={dlIdNumber}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="هوية العامل\ـة"
                      onChange={this.handleDlLaborerIqama}
                      value={dlLaborerIqama}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="تقديم الطلب من تاريخ"
                      onChange={this.handleDlCreatedFromDate}
                      value={dlCreatedFrom}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="الى تاريخ"
                      onChange={this.handleDlCreatedToDate}
                      value={dlCreatedTo}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="معالجة الطلب من تاريخ"
                      onChange={this.handleDlUpdatedFromDate}
                      value={dlUpdatedFrom}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="الى تاريخ"
                      onChange={this.handleDlUpdatedToDate}
                      value={dlUpdatedTo}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="اسم المنشأة"
                      onChange={this.handleDlCurrentSponsor}
                      value={dlCurrentSponsor}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Button
                      className={classes.searchBtn}
                      color="primary"
                      onClick={this.handleDlSearch}
                    >
                      بحث
                    </Button>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>

            {typeof dl.total === 'undefined' && (
              <Card>
                <div style={{ textAlign: 'center', padding: '10px' }}>
                  اختر معطياتك واضغط زر البحث
                </div>
              </Card>
            )}
            {this.state.api_loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} />
              </div>
            )}
            {!this.state.api_loading &&
              dl.total === 0 && (
                <Card>
                  <div style={{ textAlign: 'center', padding: '10px' }}>لا يوجد نتائج</div>
                </Card>
              )}

            {!this.state.api_loading &&
              dl.total > 0 && (
                <Card>
                  <CardBody>
                    <p>النتائج : {dl.total || 0} طلب</p>
                    <div className={classes.labelsRow}>
                      <span className={classes.label}>رقم الطلب </span>
                      <span className={classes.label}>حالة الطلب </span>
                      <span className={classes.label}>تاريخ الطلب </span>
                      <span className={`${classes.label} ${classes.l}`}>التفاصيل</span>
                      <span className={classes.label} />
                    </div>

                    {dl.data.length
                      ? dl.data.map(record => {
                          const date = new Date(record.created_at);
                          const day = `${date.getDate()}`.padStart(2, '0');
                          const month = `${date.getMonth() + 1}`.padStart(2, '0');
                          const year = date.getFullYear();
                          return (
                            <div className={classes.dataRow}>
                              <span className={classes.data}>{record.id}</span>
                              <span className={classes.data}>
                                <span
                                  className={`${classes.stateTag} ${
                                    record.status === 1
                                      ? classes[`dl_status${record.backoffice_status}`]
                                      : classes[`dl_status${record.status}`]
                                  }`}
                                >
                                  {record.status === 1
                                    ? this.backofficeStatuses[record.backoffice_status]
                                    : this.requestDLStatuses[record.status]}
                                </span>
                              </span>
                              <span className={classes.data}>{`${year}-${month}-${day}`}</span>
                              <span className={`${classes.data} ${classes.l}`}>
                                {`${record.occupation.label} / ${record.nationality.label} `}
                              </span>
                              <Button
                                className={classes.showBtn}
                                href={`/inquiries/dl/${record.id}`}
                                onClick={this.showDlRequest(record.id)}
                              >
                                عرض
                              </Button>
                              {hasPermission(
                                PERMISSIONS.INQUIRY_PROCESSING.KEY,
                                this.props.auth,
                              ) && (
                                <Tooltip
                                  title={this.tooltipMessage(record)}
                                  disableFocusListener={this.isProcessable(record)}
                                  disableHoverListener={this.isProcessable(record)}
                                  disableTouchListener={this.isProcessable(record)}
                                >
                                  <span>
                                    <Button
                                      className={
                                        this.isProcessable(record)
                                          ? classes.processBtn
                                          : classes.processBtnDisabled
                                      }
                                      disabled={!this.isProcessable(record)}
                                      href={`/inquiries/${record.existence.backoffice_id}/process`}
                                      onClick={this.processDLRequest(
                                        record.existence.backoffice_id,
                                      )}
                                    >
                                      معالجة
                                    </Button>
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </CardBody>
                  <CardFooter>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                      <TablePagination
                        style={{ border: 'none' }}
                        count={this.state.dl.total || 1}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={this.state.dl.currentPage - 1 || 0}
                        onChangePage={this.handleOnChangeDLPage}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) =>
                          ` ${from}-${to} من أصل ${count}`
                        }
                      />
                    </div>
                  </CardFooter>
                </Card>
              )}
          </GridItem>
        </Grid>
      ),
    });

    // this.state.
    // (value) => this.setState({newDlPage: value})

    pages.push({
      title: 'نقل منشأة لفرد',
      link: '/inquiries/list/2',
      content: (
        <DLTransferList
          page={this.state.newDlPage}
          setPage={value => this.setState({ newDlPage: value })}
          dlRequestId={this.state.newDlRequestId}
          setDLRequestId={value => this.setState({ newDlRequestId: value })}
          dlRequestStatus={this.state.newDlRequestStatus}
          setDLRequestStatus={value => this.setState({ newDlRequestStatus: value })}
          idNumber={this.state.newDlIdNumber}
          setIdNumber={value => this.setState({ newDlIdNumber: value })}
          iqamaNumber={this.state.newDlIqamaNumber}
          setIqamaNumber={value => this.setState({ newDlIqamaNumber: value })}
          currentSponsor={this.state.newDlCurrentSponsor}
          setCurrentSponsor={value => this.setState({ newDlCurrentSponsor: value })}
          createdFrom={this.state.newDlCreatedFrom}
          setCreatedFrom={value => this.setState({ newDlCreatedFrom: value })}
          createdTo={this.state.newDlCreatedTo}
          setCreatedTo={value => this.setState({ newDlCreatedTo: value })}
          processedFrom={this.state.newDlProcessedFrom}
          setProcessedFrom={value => this.setState({ newDlProcessedFrom: value })}
          processedTo={this.state.newDlProcessedTo}
          setProcessedTo={value => this.setState({ newDlProcessedTo: value })}
        />
      ),
    });

    pages.push({
      title: 'نقل فرد لفرد',
      link: '/inquiries/list/3',
      content: (
        <Ind2IndTransferList
          page={this.state.Ind2IndPage}
          setPage={value => this.setState({ Ind2IndPage: value })}
          requestId={this.state.Ind2IndRequestId}
          setRequestId={value => this.setState({ Ind2IndRequestId: value })}
          requestStatus={this.state.Ind2IndRequestStatus}
          setRequestStatus={value => this.setState({ Ind2IndRequestStatus: value })}
          newSponsor={this.state.Ind2IndNewSponsor}
          setNewSponsor={value => this.setState({ Ind2IndNewSponsor: value })}
          currentSponsor={this.state.Ind2IndCurrentSponsor}
          setCurrentSponsor={value => this.setState({ Ind2IndCurrentSponsor: value })}
          laborer={this.state.Ind2IndLaborer}
          setInd2IndLaborer={value => this.setState({ Ind2IndLaborer: value })}
          createdFrom={this.state.Ind2IndCreatedFrom}
          setCreatedFrom={value => this.setState({ Ind2IndCreatedFrom: value })}
          createdTo={this.state.Ind2IndCreatedTo}
          setCreatedTo={value => this.setState({ Ind2IndCreatedTo: value })}
          processedFrom={this.state.Ind2IndProcessedFrom}
          setProcessedFrom={value => this.setState({ Ind2IndProcessedFrom: value })}
          processedTo={this.state.Ind2IndProcessedTo}
          setProcessedTo={value => this.setState({ Ind2IndProcessedTo: value })}
          setLaborer={value => this.setState({ Ind2IndLaborer: value })}
        />
      ),
    });

    pages.push({
      title: ' التأشيرات الزراعية',
      link: '/inquiries/list/4',
      content: (
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon title="البحث" subtitle="الإستعلام عن الطلبات">
                <CardIcon color="info" isTableIcon>
                  <SearchIcon />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Grid container>
                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="رقم الطلب"
                      onChange={this.handleFarmerRequestId}
                      value={farmerRequestId}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <input
                      className={classes.filterInput}
                      placeholder="هوية مقدم الطلب"
                      onChange={this.handleFarmerRequestUserId}
                      value={farmerRequestUserId}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[
                        classes.filterInput,
                        classes.select,
                        !dlStatus ? classes.placeholder : {},
                      ]}
                      onChange={this.handleFarmerRequestStatus}
                      value={farmerStatus}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        حالة الطلب
                      </MenuItem>
                      <MenuItem value="" className={classes.selectItem}>
                        جميع الحالات
                      </MenuItem>
                      <MenuItem value="1" className={classes.selectItem}>
                        تحت المعالجة
                      </MenuItem>
                      <MenuItem value="2" className={classes.selectItem}>
                        مقبول
                      </MenuItem>
                      <MenuItem value="3" className={classes.selectItem}>
                        مرفوض
                      </MenuItem>
                    </Select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Select
                      className={[classes.filterInput, classes.select]}
                      onChange={this.handleFarmerVisaSource}
                      value={farmerVisaSource}
                      displayEmpty
                    >
                      <MenuItem disabled selected value="" className={classes.selectItem}>
                        مصدر الطلب
                      </MenuItem>
                      <MenuItem value="" className={classes.selectItem}>
                        كل المصادر
                      </MenuItem>
                      <MenuItem value="1" className={classes.selectItem}>
                        {this.farmerVisaRequestSource[1]}
                      </MenuItem>
                      <MenuItem value="5" className={classes.selectItem}>
                        {this.farmerVisaRequestSource[5]}
                      </MenuItem>
                    </Select>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="تقديم الطلب من تاريخ"
                      onChange={this.handleFarmerCreatedFromDate}
                      value={farmerCreatedFrom}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <DateInput
                      className={classes.filterInput}
                      placeholder="الى تاريخ"
                      onChange={this.handleFarmerCreatedToDate}
                      value={farmerCreatedTo}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={62} md={3}>
                    <Button
                      className={classes.searchBtn}
                      color="primary"
                      onClick={this.handleFarmerSearch}
                    >
                      بحث
                    </Button>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>

            {typeof farmerVisa.total === 'undefined' && (
              <Card>
                <div style={{ textAlign: 'center', padding: '10px' }}>
                  اختر معطياتك واضغط زر البحث
                </div>
              </Card>
            )}
            {this.state.api_loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} />
              </div>
            )}
            {!this.state.api_loading &&
              farmerVisa.total === 0 && (
                <Card>
                  <div style={{ textAlign: 'center', padding: '10px' }}>لا يوجد نتائج</div>
                </Card>
              )}

            {!this.state.api_loading &&
              farmerVisa.total > 0 && (
                <Card>
                  <CardBody>
                    <p>النتائج : {farmerVisa.total || 0} طلب</p>
                    <div className={classes.labelsRow}>
                      <span className={classes.label}>رقم الطلب </span>
                      <span className={classes.label}>حالة الطلب </span>
                      <span className={classes.label}>تاريخ الطلب </span>
                      <span className={`${classes.label} ${classes.l}`}>التفاصيل</span>
                      <span className={classes.label}> مصدر الطلب </span>
                      <span className={classes.label}> رقم هوية العميل </span>
                      <span className={classes.label} />
                    </div>

                    {farmerVisa.data.length
                      ? farmerVisa.data.map(record => {
                          const date = new Date(record.created_at);
                          const day = `${date.getDate()}`.padStart(2, '0');
                          const month = `${date.getMonth() + 1}`.padStart(2, '0');
                          const year = date.getFullYear();
                          return (
                            <div className={classes.dataRow}>
                              <span className={classes.data}>{record.id}</span>
                              <span className={classes.data}>
                                <span
                                  className={`${classes.stateTag} ${
                                    classes[`status${record.status}`]
                                  }`}
                                >
                                  {this.farmerRequestStatuses[record.status]}
                                </span>
                              </span>
                              <span className={classes.data}>{`${year}-${month}-${day}`}</span>
                              <span className={`${classes.data} ${classes.l}`}>
                                {`${record.occupation.label} / ${record.nationality.label} `}
                              </span>
                              <span className={classes.data}>
                                {this.farmerVisaRequestSource[record.source]}
                              </span>
                              <span className={`${classes.data} ${classes.l}`}>
                                {`${record.user.id_number}`}
                              </span>
                            </div>
                          );
                        })
                      : null}
                  </CardBody>
                  <CardFooter>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                      <TablePagination
                        style={{ border: 'none' }}
                        count={this.state.farmerVisa.total || 1}
                        rowsPerPage={ROWS_PER_PAGE}
                        page={this.state.farmerVisa.currentPage - 1 || 0}
                        onChangePage={this.handleOnChangeFarmerPage}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) =>
                          ` ${from}-${to} من أصل ${count}`
                        }
                      />
                    </div>
                  </CardFooter>
                </Card>
              )}
          </GridItem>
        </Grid>
      ),
    });
    return this.state.loading ? (
      <CircularProgress className={classes.progress} />
    ) : (
      <PageTaps title="الإستعلام عن الطلبات" pages={pages} classes={classes} />
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: {
      userPermissions: state.auth.userPermissions,
      isRoot: state.auth.isRoot,
    },
  };
};

Inquiries.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};
export default compose(
  withStyles(InquiriesStyle),
  connect(mapStateToProps),
  withRouter,
)(Inquiries);
